[ng-cloak] {
  display: none;
}

[ng-click],
[ui-sref],
.sortable th:not(.sorttable_nosort) {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.text-silver {
  color: silver;
}

[ui-view="leftBar"] {
  background: #f1f3f6;
}

#wrapper {
  margin-top: -62px !important;
  padding-top: 62px !important;
}

#wrapper.m-t-no.p-t-no {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.sel .wrapper {
  margin-bottom: 36px;
}

#header {
  top: 0;
}

.uiviewcontainer {
  position: absolute;
  bottom: 0;
  top: 62px;
  right: 0;
  left: 0;
}

body > .dropdown > .dropdown-menu li.disabled {
  display: none;
}

body > .dropdown > .dropdown-menu li.disabled a {
  color: #777;
  opacity: 0.7;
}

.cutted {
  opacity: 0.7;
}

div.choosen img {
  border: 2px solid #62cb31;
}

div.not-choosen img {
  border: 2px solid #ffffff;
}

body {
  font-size: 14px;
  color: #575748;
}

body.crem {
  font-size: 13px;
}

[ng-cloak] {
  display: none;
}

@media (min-width: 1300px) {
  .container {
    width: 1280px !important;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1480px !important;
  }
}

a {
  cursor: pointer;
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.rel, .relative {
  position: relative;
}

.abs, .absolute {
  position: absolute;
}

.fix, .fixed {
  position: fixed;
}

[ui-view] > .rel > .fix.t {
  top: 62px;
}

.t {
  top: 0;
}

.b {
  bottom: 0;
}

.l {
  left: 0;
}

.r {
  right: 0;
}

.clear {
  clear: both;
}

.f-l {
  float: left !important;
}

.f-r {
  float: right !important;
}

.f-n {
  float: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.alternate {
  background: #eeeeee;
  padding: 40px 0;
}

.border-secondary {
  border-color: #aec7ee;
}

.bg-secondary {
  background-color: #aec7ee;
}

.text-secondary {
  color: #aec7ee !important;
}

.border-white {
  border-color: #fff;
}

.bg-white {
  background-color: #fff;
}

.text-white {
  color: #fff !important;
}

.border-lightgrey {
  border-color: #f8f8f8;
}

.bg-lightgrey {
  background-color: #f8f8f8;
}

.text-lightgrey {
  color: #f8f8f8 !important;
}

.border-grey {
  border-color: #acac9d;
}

.bg-grey {
  background-color: #acac9d;
}

.text-grey {
  color: #acac9d !important;
}

.border-darkgrey {
  border-color: #080808;
}

.bg-darkgrey {
  background-color: #080808;
}

.text-darkgrey {
  color: #080808 !important;
}

table tr td > i.fa,
table tr td a > i.fa {
  font-size: 1.3em;
}

/* vars */
/* mixin */
.m-a-no {
  margin: 0px !important;
}

.m-l-no {
  margin-left: 0px !important;
}

.m-r-no {
  margin-right: 0px !important;
}

.m-h-no {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.m-t-no {
  margin-top: 0px !important;
}

.m-b-no {
  margin-bottom: 0px !important;
}

.m-v-no {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m-a-xs {
  margin: 3px !important;
}

.m-l-xs {
  margin-left: 3px !important;
}

.m-r-xs {
  margin-right: 3px !important;
}

.m-h-xs {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.m-t-xs {
  margin-top: 3px !important;
}

.m-b-xs {
  margin-bottom: 3px !important;
}

.m-v-xs {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.m-a-sm {
  margin: 8px !important;
}

.m-l-sm {
  margin-left: 8px !important;
}

.m-r-sm {
  margin-right: 8px !important;
}

.m-h-sm {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-t-sm {
  margin-top: 8px !important;
}

.m-b-sm {
  margin-bottom: 8px !important;
}

.m-v-sm {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-a-md {
  margin: 15px !important;
}

.m-l-md {
  margin-left: 15px !important;
}

.m-r-md {
  margin-right: 15px !important;
}

.m-h-md {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-t-md {
  margin-top: 15px !important;
}

.m-b-md {
  margin-bottom: 15px !important;
}

.m-v-md {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-a-lg {
  margin: 20px !important;
}

.m-l-lg {
  margin-left: 20px !important;
}

.m-r-lg {
  margin-right: 20px !important;
}

.m-h-lg {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-t-lg {
  margin-top: 20px !important;
}

.m-b-lg {
  margin-bottom: 20px !important;
}

.m-v-lg {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-a-xl {
  margin: 30px !important;
}

.m-l-xl {
  margin-left: 30px !important;
}

.m-r-xl {
  margin-right: 30px !important;
}

.m-h-xl {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-t-xl {
  margin-top: 30px !important;
}

.m-b-xl {
  margin-bottom: 30px !important;
}

.m-v-xl {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.p-a-xs {
  padding: 3px !important;
}

.p-l-xs {
  padding-left: 3px !important;
}

.p-r-xs {
  padding-right: 3px !important;
}

.p-h-xs {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.p-t-xs {
  padding-top: 3px !important;
}

.p-b-xs {
  padding-bottom: 3px !important;
}

.p-v-xs {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-a-sm {
  padding: 8px !important;
}

.p-l-sm {
  padding-left: 8px !important;
}

.p-r-sm {
  padding-right: 8px !important;
}

.p-h-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-t-sm {
  padding-top: 8px !important;
}

.p-b-sm {
  padding-bottom: 8px !important;
}

.p-v-sm {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-a-md {
  padding: 15px !important;
}

.p-l-md {
  padding-left: 15px !important;
}

.p-r-md {
  padding-right: 15px !important;
}

.p-h-md {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-t-md {
  padding-top: 15px !important;
}

.p-b-md {
  padding-bottom: 15px !important;
}

.p-v-md {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-a-lg {
  padding: 20px !important;
}

.p-l-lg {
  padding-left: 20px !important;
}

.p-r-lg {
  padding-right: 20px !important;
}

.p-h-lg {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-t-lg {
  padding-top: 20px !important;
}

.p-b-lg {
  padding-bottom: 20px !important;
}

.p-v-lg {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-a-xl {
  padding: 30px !important;
}

.p-l-xl {
  padding-left: 30px !important;
}

.p-r-xl {
  padding-right: 30px !important;
}

.p-h-xl {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-t-xl {
  padding-top: 30px !important;
}

.p-b-xl {
  padding-bottom: 30px !important;
}

.p-v-xl {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-a-no {
  padding: 0px !important;
}

.p-l-no {
  padding-left: 0px !important;
}

.p-r-no {
  padding-right: 0px !important;
}

.p-h-no {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-t-no {
  padding-top: 0px !important;
}

.p-b-no {
  padding-bottom: 0px !important;
}

.p-v-no {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.b-a-no {
  border-width: 0px !important;
  border-style: solid;
}

.b-l-no {
  border-left-width: 0px !important;
  border-left-style: solid;
}

.b-r-no {
  border-right-width: 0px !important;
  border-right-style: solid;
}

.b-h-no {
  border-left-width: 0px !important;
  border-left-style: solid;
  border-right-width: 0px !important;
  border-right-style: solid;
}

.b-t-no {
  border-top-width: 0px !important;
  border-top-style: solid;
}

.b-b-no {
  border-bottom-width: 0px !important;
  border-bottom-style: solid;
}

.b-v-no {
  border-top-width: 0px !important;
  border-top-style: solid;
  border-bottom-width: 0px !important;
  border-bottom-style: solid;
}

.b-a-xs {
  border-width: 3px !important;
  border-style: solid;
}

.b-l-xs {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.b-r-xs {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.b-h-xs {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.b-t-xs {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.b-b-xs {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.b-v-xs {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.b-a-sm {
  border-width: 8px !important;
  border-style: solid;
}

.b-l-sm {
  border-left-width: 8px !important;
  border-left-style: solid;
}

.b-r-sm {
  border-right-width: 8px !important;
  border-right-style: solid;
}

.b-h-sm {
  border-left-width: 8px !important;
  border-left-style: solid;
  border-right-width: 8px !important;
  border-right-style: solid;
}

.b-t-sm {
  border-top-width: 8px !important;
  border-top-style: solid;
}

.b-b-sm {
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

.b-v-sm {
  border-top-width: 8px !important;
  border-top-style: solid;
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

.b-a-md {
  border-width: 15px !important;
  border-style: solid;
}

.b-l-md {
  border-left-width: 15px !important;
  border-left-style: solid;
}

.b-r-md {
  border-right-width: 15px !important;
  border-right-style: solid;
}

.b-h-md {
  border-left-width: 15px !important;
  border-left-style: solid;
  border-right-width: 15px !important;
  border-right-style: solid;
}

.b-t-md {
  border-top-width: 15px !important;
  border-top-style: solid;
}

.b-b-md {
  border-bottom-width: 15px !important;
  border-bottom-style: solid;
}

.b-v-md {
  border-top-width: 15px !important;
  border-top-style: solid;
  border-bottom-width: 15px !important;
  border-bottom-style: solid;
}

.b-a-lg {
  border-width: 20px !important;
  border-style: solid;
}

.b-l-lg {
  border-left-width: 20px !important;
  border-left-style: solid;
}

.b-r-lg {
  border-right-width: 20px !important;
  border-right-style: solid;
}

.b-h-lg {
  border-left-width: 20px !important;
  border-left-style: solid;
  border-right-width: 20px !important;
  border-right-style: solid;
}

.b-t-lg {
  border-top-width: 20px !important;
  border-top-style: solid;
}

.b-b-lg {
  border-bottom-width: 20px !important;
  border-bottom-style: solid;
}

.b-v-lg {
  border-top-width: 20px !important;
  border-top-style: solid;
  border-bottom-width: 20px !important;
  border-bottom-style: solid;
}

.b-a-xl {
  border-width: 30px !important;
  border-style: solid;
}

.b-l-xl {
  border-left-width: 30px !important;
  border-left-style: solid;
}

.b-r-xl {
  border-right-width: 30px !important;
  border-right-style: solid;
}

.b-h-xl {
  border-left-width: 30px !important;
  border-left-style: solid;
  border-right-width: 30px !important;
  border-right-style: solid;
}

.b-t-xl {
  border-top-width: 30px !important;
  border-top-style: solid;
}

.b-b-xl {
  border-bottom-width: 30px !important;
  border-bottom-style: solid;
}

.b-v-xl {
  border-top-width: 30px !important;
  border-top-style: solid;
  border-bottom-width: 30px !important;
  border-bottom-style: solid;
}

.b-a-no-rec, .b-a-no-rec * {
  border: none !important;
  box-shadow: none !important;
}

.t-xxs {
  top: 1px;
}

.t-xs {
  top: 3px;
}

.t-sm {
  top: 8px;
}

.t-md {
  top: 15px;
}

.t-lg {
  top: 20px;
}

.t-xl {
  top: 30px;
}

.b-xs {
  bottom: 3px;
}

.b-sm {
  bottom: 8px;
}

.b-md {
  bottom: 15px;
}

.b-lg {
  bottom: 20px;
}

.b-xl {
  bottom: 30px;
}

.l-xs {
  left: 3px;
}

.l-sm {
  left: 8px;
}

.l-md {
  left: 15px;
}

.l-lg {
  left: 20px;
}

.l-xl {
  left: 30px;
}

.r-xs {
  right: 3px;
}

.r-sm {
  right: 8px;
}

.r-md {
  right: 15px;
}

.r-lg {
  right: 20px;
}

.r-xl {
  right: 30px;
}

.m-t-20 {
  margin-top: 20px;
}

html table.table > tbody {
  overflow-x: hidden !important;
}

input[type="text"], input[type="password"], input[type="number"] {
  cursor: text !important;
}

select {
  -webkit-appearance: menulist-button !important;
}

.CodeMirrorrrrrrrrr span.cm-keyword {
  color: blue;
}

.CodeMirrorrrrrrrrr span.cm-var, .CodeMirrorrrrrrrrr span.cm-variable-4 {
  color: red;
}

.CodeMirrorrrrrrrrr span.cm-var, .CodeMirrorrrrrrrrr span.cm-variable-5 {
  color: purple;
}

.CodeMirrorrrrrrrrr span.cm-comment {
  color: #aa7700;
}

.CodeMirrorrrrrrrrr span.cm-literal {
  color: green;
}

.CodeMirrorrrrrrrrr span.cm-operator {
  color: blue;
}

.CodeMirrorrrrrrrrr span.cm-word {
  color: black;
}

.CodeMirrorrrrrrrrr span.cm-quoted-word {
  color: #680;
}

.CodeMirrorrrrrrrrr span.cm-type {
  color: purple;
}

.CodeMirrorrrrrrrrr span.cm-separator {
  color: #666666;
}

.CodeMirrorrrrrrrrr span.cm-number {
  color: darkcyan;
}

.cm-s-default .cm-keyword {
  color: blue;
}

.cm-s-default .cm-atom {
  color: #990061;
}

.cm-s-default .cm-number {
  color: darkcyan;
}

.cm-s-default .cm-variable-4 {
  color: red;
}

.cm-s-default .cm-variable-5 {
  color: #b3005c;
}

.cm-s-default .cm-string {
  color: green;
}

.cm-s-default .cm-builtin {
  color: darkorange;
}

.editbox p {
  margin: 0;
}

.columns-4-400 {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  -moz-column-width: 400px;
  -webkit-column-width: 400px;
  column-width: 400px;
  width: 100%;
  overflow-x: auto;
}

.columns-4-600 {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  -moz-column-width: 600px;
  -webkit-column-width: 600px;
  column-width: 600px;
  width: 100%;
  overflow-x: auto;
}

.selmodal {
  display: block;
  margin: 15px 15px 56px 15px;
}

.selmodal .modal-dialog {
  margin-top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.selmodal .modal-dialog .modal-content {
  height: 100%;
}

.greygradient {
  z-index: 2000 !important;
}

.sel .normalheader ul, .sel .normalheader li, .sel .normalheader p {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sel .normalheader ul li ul li {
  margin-left: 10px;
}

.sel .normalheader.small-header .panel-body {
  max-height: 100px;
  overflow: auto;
}

.sel .normalheader ul.nav.nav-pills.f-r li.active a {
  background: white;
  color: #A4251F;
}

.sel .normalheader ul.nav.nav-pills.f-r li.active a > i {
  padding-bottom: 3px;
  border-bottom: 2px solid #A4251F;
}

.text-black {
  color: black;
}

@media (min-width: 768px) {
  .modal-sm {
    width: 400px;
  }
}

.sel .first-border-top-white :first-child th {
  border-top-color: white;
}

.search_output_box {
  overflow: auto;
  width: auto;
}

.search_output {
  font-family: monospace;
  color: black;
  font-size: 11px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-collapse: collapse;
  margin: 0 !important;
}

.search_output th, .search_output tr.thead td {
  text-align: left;
  padding: 3px;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  white-space: nowrap;
}

.search_output td {
  text-align: left;
  padding: 0px 5px;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
}

.search_output .padd td {
  padding: 3px;
}

.search_output tr:hover {
  background-color: #EFE0E0 !important;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
}

.search_output td:hover {
  background-color: #DFDFFF !important;
}

.search_output .even {
  background-color: #3D3D3D;
}

.search_output .odd {
  background-color: #6E6E6E;
}

.search_output .highlight {
  background-color: #3D3D3D;
  font-weight: bold;
}

.search_output thead tr, .search_output tr.thead {
  background-image: url(../img/header-min.png);
  background-repeat: repeat-x;
  background-position: center left;
}

.search_output tbody {
  width: 100px;
  overflow: hidden;
}

.search_output .header {
  background-image: url(../img/header-bg.png);
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 30px;
  height: auto;
}

.search_output .headerSortUp {
  background-image: url(../img/header-asc.png);
  background-repeat: no-repeat;
  padding-left: 30px;
  padding-top: 8px;
  height: auto;
}

.search_output .headerSortDown {
  background-image: url(../img/header-desc.png);
  background-repeat: no-repeat;
  padding-left: 30px;
  padding-top: 8px;
  height: auto;
}

.search_output.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.search_output.table > tbody > tr > th {
  border-top: 1px solid grey;
}

.logo {
  font-size: 25px;
  position: relative;
  top: 13px;
  left: 20px;
}

a.logo, .dont-underline, a.dont-underline, a.dont-underline:hover, a.dont-underline:active, a.dont-underline:focused {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Pe-icon-7-stroke';
  src: url("../fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("../fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("../fonts/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("../fonts/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="pe-7s-"], [class*=" pe-7s-"] {
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
  content: "\e6aa";
}

.pe-7s-arc:before {
  content: "\e6ab";
}

.pe-7s-back-2:before {
  content: "\e6ac";
}

.pe-7s-bandaid:before {
  content: "\e6ad";
}

.pe-7s-car:before {
  content: "\e6ae";
}

.pe-7s-diamond:before {
  content: "\e6af";
}

.pe-7s-door-lock:before {
  content: "\e6b0";
}

.pe-7s-eyedropper:before {
  content: "\e6b1";
}

.pe-7s-female:before {
  content: "\e6b2";
}

.pe-7s-gym:before {
  content: "\e6b3";
}

.pe-7s-hammer:before {
  content: "\e6b4";
}

.pe-7s-headphones:before {
  content: "\e6b5";
}

.pe-7s-helm:before {
  content: "\e6b6";
}

.pe-7s-hourglass:before {
  content: "\e6b7";
}

.pe-7s-leaf:before {
  content: "\e6b8";
}

.pe-7s-magic-wand:before {
  content: "\e6b9";
}

.pe-7s-male:before {
  content: "\e6ba";
}

.pe-7s-map-2:before {
  content: "\e6bb";
}

.pe-7s-next-2:before {
  content: "\e6bc";
}

.pe-7s-paint-bucket:before {
  content: "\e6bd";
}

.pe-7s-pendrive:before {
  content: "\e6be";
}

.pe-7s-photo:before {
  content: "\e6bf";
}

.pe-7s-piggy:before {
  content: "\e6c0";
}

.pe-7s-plugin:before {
  content: "\e6c1";
}

.pe-7s-refresh-2:before {
  content: "\e6c2";
}

.pe-7s-rocket:before {
  content: "\e6c3";
}

.pe-7s-settings:before {
  content: "\e6c4";
}

.pe-7s-shield:before {
  content: "\e6c5";
}

.pe-7s-smile:before {
  content: "\e6c6";
}

.pe-7s-usb:before {
  content: "\e6c7";
}

.pe-7s-vector:before {
  content: "\e6c8";
}

.pe-7s-wine:before {
  content: "\e6c9";
}

.pe-7s-cloud-upload:before {
  content: "\e68a";
}

.pe-7s-cash:before {
  content: "\e68c";
}

.pe-7s-close:before {
  content: "\e680";
}

.pe-7s-bluetooth:before {
  content: "\e68d";
}

.pe-7s-cloud-download:before {
  content: "\e68b";
}

.pe-7s-way:before {
  content: "\e68e";
}

.pe-7s-close-circle:before {
  content: "\e681";
}

.pe-7s-id:before {
  content: "\e68f";
}

.pe-7s-angle-up:before {
  content: "\e682";
}

.pe-7s-wristwatch:before {
  content: "\e690";
}

.pe-7s-angle-up-circle:before {
  content: "\e683";
}

.pe-7s-world:before {
  content: "\e691";
}

.pe-7s-angle-right:before {
  content: "\e684";
}

.pe-7s-volume:before {
  content: "\e692";
}

.pe-7s-angle-right-circle:before {
  content: "\e685";
}

.pe-7s-users:before {
  content: "\e693";
}

.pe-7s-angle-left:before {
  content: "\e686";
}

.pe-7s-user-female:before {
  content: "\e694";
}

.pe-7s-angle-left-circle:before {
  content: "\e687";
}

.pe-7s-up-arrow:before {
  content: "\e695";
}

.pe-7s-angle-down:before {
  content: "\e688";
}

.pe-7s-switch:before {
  content: "\e696";
}

.pe-7s-angle-down-circle:before {
  content: "\e689";
}

.pe-7s-scissors:before {
  content: "\e697";
}

.pe-7s-wallet:before {
  content: "\e600";
}

.pe-7s-safe:before {
  content: "\e698";
}

.pe-7s-volume2:before {
  content: "\e601";
}

.pe-7s-volume1:before {
  content: "\e602";
}

.pe-7s-voicemail:before {
  content: "\e603";
}

.pe-7s-video:before {
  content: "\e604";
}

.pe-7s-user:before {
  content: "\e605";
}

.pe-7s-upload:before {
  content: "\e606";
}

.pe-7s-unlock:before {
  content: "\e607";
}

.pe-7s-umbrella:before {
  content: "\e608";
}

.pe-7s-trash:before {
  content: "\e609";
}

.pe-7s-tools:before {
  content: "\e60a";
}

.pe-7s-timer:before {
  content: "\e60b";
}

.pe-7s-ticket:before {
  content: "\e60c";
}

.pe-7s-target:before {
  content: "\e60d";
}

.pe-7s-sun:before {
  content: "\e60e";
}

.pe-7s-study:before {
  content: "\e60f";
}

.pe-7s-stopwatch:before {
  content: "\e610";
}

.pe-7s-star:before {
  content: "\e611";
}

.pe-7s-speaker:before {
  content: "\e612";
}

.pe-7s-signal:before {
  content: "\e613";
}

.pe-7s-shuffle:before {
  content: "\e614";
}

.pe-7s-shopbag:before {
  content: "\e615";
}

.pe-7s-share:before {
  content: "\e616";
}

.pe-7s-server:before {
  content: "\e617";
}

.pe-7s-search:before {
  content: "\e618";
}

.pe-7s-film:before {
  content: "\e6a5";
}

.pe-7s-science:before {
  content: "\e619";
}

.pe-7s-disk:before {
  content: "\e6a6";
}

.pe-7s-ribbon:before {
  content: "\e61a";
}

.pe-7s-repeat:before {
  content: "\e61b";
}

.pe-7s-refresh:before {
  content: "\e61c";
}

.pe-7s-add-user:before {
  content: "\e6a9";
}

.pe-7s-refresh-cloud:before {
  content: "\e61d";
}

.pe-7s-paperclip:before {
  content: "\e69c";
}

.pe-7s-radio:before {
  content: "\e61e";
}

.pe-7s-note2:before {
  content: "\e69d";
}

.pe-7s-print:before {
  content: "\e61f";
}

.pe-7s-network:before {
  content: "\e69e";
}

.pe-7s-prev:before {
  content: "\e620";
}

.pe-7s-mute:before {
  content: "\e69f";
}

.pe-7s-power:before {
  content: "\e621";
}

.pe-7s-medal:before {
  content: "\e6a0";
}

.pe-7s-portfolio:before {
  content: "\e622";
}

.pe-7s-like2:before {
  content: "\e6a1";
}

.pe-7s-plus:before {
  content: "\e623";
}

.pe-7s-left-arrow:before {
  content: "\e6a2";
}

.pe-7s-play:before {
  content: "\e624";
}

.pe-7s-key:before {
  content: "\e6a3";
}

.pe-7s-plane:before {
  content: "\e625";
}

.pe-7s-joy:before {
  content: "\e6a4";
}

.pe-7s-photo-gallery:before {
  content: "\e626";
}

.pe-7s-pin:before {
  content: "\e69b";
}

.pe-7s-phone:before {
  content: "\e627";
}

.pe-7s-plug:before {
  content: "\e69a";
}

.pe-7s-pen:before {
  content: "\e628";
}

.pe-7s-right-arrow:before {
  content: "\e699";
}

.pe-7s-paper-plane:before {
  content: "\e629";
}

.pe-7s-delete-user:before {
  content: "\e6a7";
}

.pe-7s-paint:before {
  content: "\e62a";
}

.pe-7s-bottom-arrow:before {
  content: "\e6a8";
}

.pe-7s-notebook:before {
  content: "\e62b";
}

.pe-7s-note:before {
  content: "\e62c";
}

.pe-7s-next:before {
  content: "\e62d";
}

.pe-7s-news-paper:before {
  content: "\e62e";
}

.pe-7s-musiclist:before {
  content: "\e62f";
}

.pe-7s-music:before {
  content: "\e630";
}

.pe-7s-mouse:before {
  content: "\e631";
}

.pe-7s-more:before {
  content: "\e632";
}

.pe-7s-moon:before {
  content: "\e633";
}

.pe-7s-monitor:before {
  content: "\e634";
}

.pe-7s-micro:before {
  content: "\e635";
}

.pe-7s-menu:before {
  content: "\e636";
}

.pe-7s-map:before {
  content: "\e637";
}

.pe-7s-map-marker:before {
  content: "\e638";
}

.pe-7s-mail:before {
  content: "\e639";
}

.pe-7s-mail-open:before {
  content: "\e63a";
}

.pe-7s-mail-open-file:before {
  content: "\e63b";
}

.pe-7s-magnet:before {
  content: "\e63c";
}

.pe-7s-loop:before {
  content: "\e63d";
}

.pe-7s-look:before {
  content: "\e63e";
}

.pe-7s-lock:before {
  content: "\e63f";
}

.pe-7s-lintern:before {
  content: "\e640";
}

.pe-7s-link:before {
  content: "\e641";
}

.pe-7s-like:before {
  content: "\e642";
}

.pe-7s-light:before {
  content: "\e643";
}

.pe-7s-less:before {
  content: "\e644";
}

.pe-7s-keypad:before {
  content: "\e645";
}

.pe-7s-junk:before {
  content: "\e646";
}

.pe-7s-info:before {
  content: "\e647";
}

.pe-7s-home:before {
  content: "\e648";
}

.pe-7s-help2:before {
  content: "\e649";
}

.pe-7s-help1:before {
  content: "\e64a";
}

.pe-7s-graph3:before {
  content: "\e64b";
}

.pe-7s-graph2:before {
  content: "\e64c";
}

.pe-7s-graph1:before {
  content: "\e64d";
}

.pe-7s-graph:before {
  content: "\e64e";
}

.pe-7s-global:before {
  content: "\e64f";
}

.pe-7s-gleam:before {
  content: "\e650";
}

.pe-7s-glasses:before {
  content: "\e651";
}

.pe-7s-gift:before {
  content: "\e652";
}

.pe-7s-folder:before {
  content: "\e653";
}

.pe-7s-flag:before {
  content: "\e654";
}

.pe-7s-filter:before {
  content: "\e655";
}

.pe-7s-file:before {
  content: "\e656";
}

.pe-7s-expand1:before {
  content: "\e657";
}

.pe-7s-exapnd2:before {
  content: "\e658";
}

.pe-7s-edit:before {
  content: "\e659";
}

.pe-7s-drop:before {
  content: "\e65a";
}

.pe-7s-drawer:before {
  content: "\e65b";
}

.pe-7s-download:before {
  content: "\e65c";
}

.pe-7s-display2:before {
  content: "\e65d";
}

.pe-7s-display1:before {
  content: "\e65e";
}

.pe-7s-diskette:before {
  content: "\e65f";
}

.pe-7s-date:before {
  content: "\e660";
}

.pe-7s-cup:before {
  content: "\e661";
}

.pe-7s-culture:before {
  content: "\e662";
}

.pe-7s-crop:before {
  content: "\e663";
}

.pe-7s-credit:before {
  content: "\e664";
}

.pe-7s-copy-file:before {
  content: "\e665";
}

.pe-7s-config:before {
  content: "\e666";
}

.pe-7s-compass:before {
  content: "\e667";
}

.pe-7s-comment:before {
  content: "\e668";
}

.pe-7s-coffee:before {
  content: "\e669";
}

.pe-7s-cloud:before {
  content: "\e66a";
}

.pe-7s-clock:before {
  content: "\e66b";
}

.pe-7s-check:before {
  content: "\e66c";
}

.pe-7s-chat:before {
  content: "\e66d";
}

.pe-7s-cart:before {
  content: "\e66e";
}

.pe-7s-camera:before {
  content: "\e66f";
}

.pe-7s-call:before {
  content: "\e670";
}

.pe-7s-calculator:before {
  content: "\e671";
}

.pe-7s-browser:before {
  content: "\e672";
}

.pe-7s-box2:before {
  content: "\e673";
}

.pe-7s-box1:before {
  content: "\e674";
}

.pe-7s-bookmarks:before {
  content: "\e675";
}

.pe-7s-bicycle:before {
  content: "\e676";
}

.pe-7s-bell:before {
  content: "\e677";
}

.pe-7s-battery:before {
  content: "\e678";
}

.pe-7s-ball:before {
  content: "\e679";
}

.pe-7s-back:before {
  content: "\e67a";
}

.pe-7s-attention:before {
  content: "\e67b";
}

.pe-7s-anchor:before {
  content: "\e67c";
}

.pe-7s-albums:before {
  content: "\e67d";
}

.pe-7s-alarm:before {
  content: "\e67e";
}

.pe-7s-airplay:before {
  content: "\e67f";
}
